import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { postApi } from "./Helper/Axios";
function Signup({ handleClose, fetchData }) {
  const navigate = useNavigate()
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formdata, setFormdata] = useState({
    email: "",
    password: "",
  });

  const location = useLocation()
  console.log("👉 ~ file: Signup.js:17 ~ Signup ~ location:", location.pathname)

  const successNotify = (message) =>
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const formik = useFormik({
    initialValues: formdata,
    validationSchema: Yup.object({
      email: Yup.string()
        .max(15, "Must be 15 characters or less")
        .required("email Required"),
      // name: Yup.string().required("name Required"),
      password: Yup.string().required("password Required"),
    }),
    onSubmit: async (values) => {
      const url = "add-admin";
      await postApi(url, values)
        .then((res) => {
          if (res.status === 200) {
            successNotify(res.message);
            formik.resetForm();
            if (location.pathname === "/settings") {
              handleClose();
              fetchData();
            } else {
              navigate('/settings')
            }
          }
        })
        .catch((err) => {
          console.log("Fetching error while updating data", err);
          setError(err.response.data.message);
        });
    },
  });
  console.log("errrorr", success);
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  useEffect(() => {
    let timer;
    if (error) {
      timer = setTimeout(() => {
        setError("");
      }, 3000); // 3000 milliseconds = 3 seconds
    }
    if (success) {
      timer = setTimeout(() => {
        setSuccess("");
      }, 3000); // 3000 milliseconds = 3 seconds
    }
    return () => {
      clearTimeout(timer);
    };
  }, [error, success]);
  return (
    <section
      className="login-section d-flex  
        align-items-center  
        justify-content-center  
       "
    >
      <div className="loginformWrap w-50">
        <form onSubmit={formik.handleSubmit}>
          <div className="login-feild-wrap">
            <div className="myinput">
              <input
                type="text"
                placeholder="Username"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                className={formik.errors.email ? "errorinput" : ""}
              />
            </div>
          </div>
          <div className="error">
            {formik.touched.email && formik.errors.email ? (
              <p>{formik.errors.email}</p>
            ) : null}
          </div>
          <div className="login-feild-wrap">
            <div className="myinput">
              <input
                type={showConfirmPassword ? "text" : "password"}
                placeholder="password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                className={formik.errors.password ? "errorinput" : ""}
              />
              <span className="eye" onClick={toggleConfirmPasswordVisibility}>
                {showConfirmPassword ? <FiEyeOff /> : <FiEye />}
              </span>
            </div>
          </div>
          <div className="error">
            {formik.touched.password && formik.errors.password ? (
              <p>{formik.errors.password}</p>
            ) : null}
          </div>
          <div className="d-flex justify-content-end">
            <button type="submit" className="mybtn">
              Create Sub Admin
            </button>
          </div>
          <div className="text-success mt-2">
            {success && success ? <p>{success}</p> : null}
          </div>
          <div className="error mt-2">
            {error && error ? <p>{error}</p> : null}
          </div>
        </form>
      </div>
    </section>
  );
}

export default Signup;
