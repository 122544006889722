import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/esm/Table";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { ReactComponent as SubSvg } from "../images/sub.svg";
import { getApi, postApi } from "./Helper/Axios";
import Signup from "./Signup";
import SubChangePassword from "./SubChangePassword";
const permissionsSchema = {
  create: false,
  read: false,
  update: false,
  delete: false,
};
export default function SettingPermission() {
  const initialValues = {
    searchengines: { ...permissionsSchema },
    sites: { ...permissionsSchema },
    wallpapers: { ...permissionsSchema },
    trendingsearches: { ...permissionsSchema },
    games: { ...permissionsSchema },
    top: { ...permissionsSchema },
    archive: { ...permissionsSchema },
    moreapps: { ...permissionsSchema },
  };
  const [allPermissionsChecked, setAllPermissionsChecked] = useState(false);
  const [subadmins, setSubadmins] = useState();
  const [checkid, setCheckid] = useState(false);
  const [permission, setPermission] = useState(initialValues);
  const [id, setId] = useState("");
  const [deleteid, setdeleteid] = useState(0);
  const [showDelete, setShowDelete] = useState(false);
  const handleDeleteClose = () => setShowDelete(false);
  const [pageLoader, setPageLoader] = useState(false);
  const fetchData = async () => {
    setPageLoader(true);
    await getApi("admin-get-admin", true)
      .then((res) => {
        setSubadmins(res.data);
        setPageLoader(false);
      })
      .catch((err) => {
        console.log("err", err);
        setPageLoader(false);
      });
  };
  const handleDeleteShow = () => setShowDelete(true);
  useEffect(() => {
    fetchData();
    handleClose()
  }, []);
  const handleStatusChange = async (statuss, id) => {
    const newdata = {
      is_active: statuss,
    };
    await postApi(`admin-status-change/${id}`, newdata, true)
      .then((res) => {
        if (res.status === 200) {
          successNotify(res.message);
          fetchData();
        }
      })
      .catch((err) => {
        console.log(err);
        errorNotify(err.response.message);
      });
  };
  // Function to toggle all permissions for a module
  const toggleAllPermissions = (module, value) => {
    const updatedPermissions = { ...formik.values[module] };
    Object.keys(updatedPermissions).forEach((permission) => {
      updatedPermissions[permission] = value;
    });
    formik.setFieldValue(module, updatedPermissions);
  };

  // Function to handle single permission change
  const handlePermissionChange = (module, permission, value) => {
    formik.setFieldValue(`${module}.${permission}`, value);
  };

  const formik = useFormik({
    initialValues: permission,
    enableReinitialize: true,
    onSubmit: async (values) => {
      let obj = {
        ...values,
      };
      await postApi(`admin-change-permission/${id}`, { permission: obj }, true)
        .then((res) => {
          if (res.status === 200) {
            formik.resetForm();
            setCheckid(false);
            handleClose();
            handleClosepass();
          }
        })
        .catch((err) => console.log("errr", err));
    },
  });
  const handlecheck = async (id) => {
    setCheckid(true);
    setId(id);
    formik.setFieldValue("checkid", id);
    if (id !== "") {
      await getApi(`admin-get-permisiion/${id}`, true)
        .then((res) => {
          if (res.data.permission === " " || res.data.permission === null) {
            setPermission(initialValues);
          }
          if (res.status === 200) {
            if (res.data.permission !== null) {
              let permisiion = JSON.parse(res.data.permission);
              setPermission(permisiion);
            }
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showpass, setShowpass] = useState(false);
  const successNotify = (message) =>
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const errorNotify = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const handleClosepass = () => setShowpass(false);
  const handleShowpass = () => setShowpass(true);
  const handleDelete = async (id) => {
    await getApi(`admin-delete-subadmin/${id}`, localStorage.getItem("token"))
      .then((res) => {
        if (res.status === 200) {
          fetchData();
          successNotify(res.message);
          handleDeleteClose();
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <div className="d-flex justify-content-between userr">
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create Sub Admin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Signup handleClose={handleClose} fetchData={fetchData} />
        </Modal.Body>
      </Modal>
      <Modal show={showpass} onHide={handleClosepass}>
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SubChangePassword id={id} />
        </Modal.Body>
      </Modal>
      <div className="col-3 user-info">
        <div className="subadminlist">
          <div className="login-title d-flex justify-content-between">
            <h5>Sub Admin List</h5>
            <button className="subbtn" onClick={handleShow}>
              <SubSvg className="marginright" />
              Create Sub Admin
            </button>
          </div>

          <Table responsive="sm" className={`mytable mt-2`}>
            <thead>
              <tr>
                <th>name</th>
                <th>status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {!pageLoader ? (
                subadmins &&
                subadmins.map((subadmin) => (
                  <tr
                    className={`mycheckbox ${id === subadmin.id ? "borderg" : ""
                      }`}
                    key={subadmin.id}
                  >
                    <td onClick={() => handlecheck(subadmin.id)}>
                      {subadmin.email}
                    </td>
                    <td>
                      <div
                        className={`statussubadmin ${subadmin.is_active === 1 ? "green" : ""
                          }`}
                        onClick={() =>
                          handleStatusChange(
                            subadmin.is_active === 1 ? 0 : 1,
                            subadmin.id
                          )
                        }
                      ></div>
                    </td>
                    <td>
                      <button
                        className="showWrap marginright"
                        onClick={() => {
                          handleShowpass();
                          setId(subadmin.id);
                        }}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="pass"
                        >
                          <path
                            d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM12 18.25C10.21 18.25 8.81 17.36 7.86 16.48V17.19C7.86 17.6 7.52 17.94 7.11 17.94C6.7 17.94 6.36 17.6 6.36 17.19V14.44C6.36 14.03 6.7 13.69 7.11 13.69H9.59C10 13.69 10.34 14.03 10.34 14.44C10.34 14.85 10 15.19 9.59 15.19H8.69C9.43 15.93 10.58 16.75 12 16.75C14.62 16.75 16.75 14.62 16.75 12C16.75 11.59 17.09 11.25 17.5 11.25C17.91 11.25 18.25 11.59 18.25 12C18.25 15.45 15.45 18.25 12 18.25ZM18.25 9.54C18.25 9.57 18.25 9.6 18.25 9.62C18.24 9.73 18.21 9.83 18.16 9.92C18.11 10.01 18.04 10.09 17.95 10.16C17.88 10.21 17.8 10.25 17.71 10.28C17.64 10.3 17.57 10.31 17.5 10.31H15.07C14.66 10.31 14.32 9.97 14.32 9.56C14.32 9.15 14.66 8.81 15.07 8.81H15.9C15.1 8.07 13.81 7.25 12.02 7.25C9.4 7.25 7.27 9.38 7.27 12C7.27 12.41 6.93 12.75 6.52 12.75C6.11 12.75 5.75 12.41 5.75 12C5.75 8.55 8.55 5.75 12 5.75C14.15 5.75 15.73 6.68 16.75 7.57V6.81C16.75 6.4 17.09 6.06 17.5 6.06C17.91 6.06 18.25 6.4 18.25 6.81V9.54Z"
                            fill="currentcolor"
                          />
                        </svg>
                      </button>

                      <button
                        className="showWrap"
                        onClick={() => {
                          handleDeleteShow();
                          setdeleteid(subadmin.id);
                        }}
                      //   onClick={() => handleDelete(row.id)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                        >
                          <path
                            fill="currentcolor"
                            d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"
                          />
                        </svg>
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="text-center" colSpan={3}>
                    <div className="loaderWrap">
                      <div className="loader-15"></div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <Modal show={showDelete} onHide={handleDeleteClose}>
            <Modal.Header closeButton>
              <Modal.Title>Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to delete Sub admin.</Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                className="mybtn black"
                onClick={handleDeleteClose}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                className="mybtn"
                onClick={() => handleDelete(deleteid)}
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
      {checkid ? (
        <div className="col-8 user-info">
          <div className="permission-title">
            <h4>Sub Admin Permission</h4>
          </div>
          <form onSubmit={formik.handleSubmit}>
            {/* Render permission checkboxes for each module */}
            {Object.keys(permission).map((module) => (
              <div className="categoryWrap" key={module}>
                <h5>{module}</h5>
                <div className="d-flex">
                  {/* Checkbox to toggle all permissions */}
                  {module !== "user" && module !== "payment" && (
                    <div className="d-flex">
                      <div className="checkbox marginright">
                        <input
                          type="checkbox"
                          className="checkbox__input"
                          checked={Object.values(formik.values[module]).every(
                            Boolean
                          )}
                          onChange={(e) => {
                            const newValue = e.target.checked;
                            toggleAllPermissions(module, newValue);
                            setAllPermissionsChecked(newValue);
                          }}
                        />
                        <span className="checkbox__in">
                          <span className="checkbox__tick"></span>
                        </span>
                      </div>
                      <label> All Permissions</label>
                    </div>
                  )}
                  {/* Individual permission checkboxes */}
                  {Object.keys(permission[module]).map((permission) => (
                    <div className="d-flex">
                      <div className="checkbox marginright">
                        <input
                          type="checkbox"
                          className="checkbox__input"
                          checked={formik.values[module][permission]}
                          onChange={(e) =>
                            handlePermissionChange(
                              module,
                              permission,
                              e.target.checked
                            )
                          }
                        />
                        <span className="checkbox__in">
                          <span className="checkbox__tick"></span>
                        </span>
                      </div>
                      <label>
                        {" "}
                        {permission.charAt(0).toUpperCase() +
                          permission.slice(1)}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            ))}

            <button type="submit" className="mybtn">
              Submit
            </button>
          </form>
        </div>
      ) : null}
    </div>
  );
}
