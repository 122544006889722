import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { ReactComponent as CategorySvg } from "../images/category.svg";
import { getApi, postApi } from "./Helper/Axios";
import MyContext from "./MyContext";
import Popup from "./Popup";
export default function Sites() {
  let permission_module;
  const location = useLocation();
  const urlName = location.pathname;
  let module = urlName.replace(/^\/|\/$/g, "");

  const user = useSelector((state) => state.auth.user);

  const isSuperAdmin = user && user.is_superadmin;

  const permission = user && JSON.parse(user?.permission);

  if (permission !== null) {
    permission_module = { ...permission[module] };
  }
  if (isSuperAdmin === 1) {
    permission_module = {
      read: true,
      create: true,
      update: true,
      delete: true,
    };
  }
  const [shows, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [data, setData] = useState();
  const [isupdate, setIsupdate] = useState(false);
  const [categoryname, setCategoryName] = useState("sites");
  const [categoryId, setCategoryId] = useState();
  const [updatedata, setUpdateData] = useState({});
  const [deleteid, setdeleteid] = useState(0);
  const [id, setId] = useState();
  const [siteCategory, setSiteCategory] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [modaltxt, setmodaltxt] = useState("Delete");
  const handleDeleteClose = () => setShowDelete(false);
  const handleDeleteShow = () => setShowDelete(true);
  const [dataSite, setDataSite] = useState([]);
  const [error, setError] = useState();
  const [pageLoader, setPageLoader] = useState(false);
  const formConfig = {
    fields: [
      {
        name: "name",
        label: "Name",
        type: "text",
        placeholder: "enter name",
      },
      {
        name: "description",
        label: "Site Description",
        type: "text",
        placeholder: "enter description",
      },
      {
        name: "icon",
        label: "Icon",
        type: "file",
      },
      {
        name: "bg_image",
        label: "Background Image",
        type: "file1",
      },
      {
        name: "bg_color",
        label: "Background Color",
        type: "text",
      },
      {
        name: "status",
        label: "Status",
        type: "switch",
      },
    ],
    validationSchema: Yup.object({
      name: Yup.string().required("name Required"),
      icon: Yup.mixed().required("Please upload an image"),
    }),
  };
  const formConfigSites = {
    fields: [
      {
        name: "site_name",
        label: "Site Name",
        type: "text",
        placeholder: "enter name",
      },
      {
        name: "description",
        label: "Site Description",
        type: "text",
        placeholder: "enter description",
      },
      {
        name: "title_color",
        label: "Title Color",
        type: "color",
        class: "cls",
      },
      {
        name: "desc_color",
        label: "Description Color",
        type: "color",
        class: "cls",
      },
      {
        name: "field_color",
        label: "Field Color",
        type: "color",
        class: "cls",
      },
      {
        name: "icon_color",
        label: "Icon Color",
        type: "color",
        class: "cls",
      },
      {
        name: "url",
        label: "Url",
        type: "text",
        placeholder: "enter url",
      },
      {
        name: "icon",
        label: "Icon",
        type: "file",
      },
      {
        name: "status",
        label: "Status",
        type: "switch",
      },
    ],
    validationSchema: Yup.object({
      site_name: Yup.string().required("name Required"),
      description: Yup.string().required("description Required"),
      url: Yup.string().required("url Required"),
      icon: Yup.mixed().required("Please upload an image"),
    }),
  };
  const initialValuesSites = {
    site_name: "",
    icon: "",
    url: "",
    status: false,
    description: "",
    title_color: "",
    desc_color: "",
    icon_color: "",
    field_color: '',
  };
  const initialValues = {
    name: "",
    icon: "",
    status: false,
    description: "",
    bg_color: '',
    bg_image: ''
  };
  const successNotify = (message) =>
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const errorNotify = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const fetchData = async () => {
    setPageLoader(true);
    let url = "get-site-category";
    await getApi(url, true)
      .then((response) => {
        if (response.status === 401) {
          setError(response.data);
        }
        setSiteCategory(response.data.sitecategory);
        setPageLoader(false);
      })
      .catch((err) => {
        setPageLoader(false);
      });
    await getApi("get-site", true)
      .then(async (response) => {
        let newData = [...response.data];
        if (response.status === 200) {
          setDataSite(newData);
        }
        if (response.status === 401) {
          setError(response.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);
  const value = {
    categoryId: categoryId,
    title: categoryname,
    isupdate: isupdate,
    handleClose: handleClose,
    isShow: shows,
    formConfig: categoryname === "sites" ? formConfig : formConfigSites,
    url: categoryname === "sites" ? "add-site-category" : "add-site",
    data:
      categoryname === "sites"
        ? isupdate
          ? updatedata
          : initialValuesSites
        : isupdate
          ? updatedata
          : initialValues,
    fetchData: fetchData,
    updatteurl: categoryname !== "sites" ? "edit-site" : "edit-site-category",
    id: id,
    axiosHeader: true,
  };

  const handleStatusChange = async (statuss, id, name = null) => {
    const newdata = {
      status: statuss,
    };
    let url = name === null ? "status-site-category" : "status-site";
    await postApi(`${url}?status=${id}`, newdata, true)
      .then((res) => {
        if (res.status === 200) {
          successNotify(res.message);
          fetchData();
        }
      })
      .catch((err) => console.log(err));
  };
  const handleUpdate = async (id, name = null) => {
    let updateurl = name === null ? "get-site-category" : "get-site";
    let query = name === null ? "sitecategory" : "site";
    await getApi(`${updateurl}?${query}=${id}`, true)
      .then((res) => {
        name === null
          ? setUpdateData(res.data.sitecategory)
          : setUpdateData(res.data);
        setIsupdate(true);
        handleShow();
      })
      .catch((err) => console.log(err));
  };
  const handleDelete = async (id) => {
    let deleteurl =
      modaltxt === "Archive" ? "archive-site" : "delete-site-category";
    let query = modaltxt === "Archive" ? "site" : "category";
    await getApi(`${deleteurl}?${query}=${id}`, true)
      .then((res) => {
        if (res.status === 200) {
          fetchData();
          errorNotify(res.message);
          handleDeleteClose();
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <MyContext.Provider value={value}>
      {error ? (
        <div className="alert-danger text-center alert">
          <p>{error}</p>
        </div>
      ) : (
        <>
          <div>
            <h4 className="d-flex justify-content-between">Sites</h4>
            <div className="sitesWrap">
              <div className="mb-4 d-flex justify-content-between align-items-center">
                <div className="d-flex">
                  <CategorySvg />
                  <h6>Category</h6>
                </div>
                {permission_module?.create === true ? (
                  <div
                    className="mybtn"
                    onClick={() => {
                      handleShow();
                      setCategoryName("sites");
                      setIsupdate(false);
                    }}
                  >
                    Add
                  </div>
                ) : null}
              </div>
              <div className="d-flex flex-wrap">
                {!pageLoader ? (
                  siteCategory &&
                  siteCategory.map((category) => (
                    <div
                      className="d-flex sitecategorywrap align-items-center"
                      key={category.id}
                    >
                      <div className="siteCateIcon marginright">
                        <img
                          src={`${process.env.REACT_APP_UPLOADS_URL}icons/${category.icon}`}
                          alt="siteCat"
                        />
                      </div>
                      <div className="siteCatContent">{category.name}</div>
                      {permission_module?.update === true ? (
                        <div
                          className="iconWrap edit"
                          onClick={() => {
                            setCategoryName("sites");
                            handleUpdate(category.id);
                            setId(category.id);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path
                              fill="currentcolor"
                              d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"
                            />
                          </svg>
                        </div>
                      ) : null}
                      {permission_module?.update === true ? (
                        <div
                          className="iconWrap view"
                          onClick={() =>
                            handleStatusChange(!category.status, category.id)
                          }
                        >
                          {category.status === true ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 576 512"
                            >
                              <path
                                fill="currentcolor"
                                d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 640 512"
                            >
                              <path
                                fill="currentcolor"
                                d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z"
                              />
                            </svg>
                          )}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 576 512"
                          >
                            <path
                              fill="currentcolor"
                              d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"
                            />
                          </svg>
                        </div>
                      ) : null}
                      {permission_module?.delete === true ? (
                        <div
                          className="iconWrap"
                          onClick={() => {
                            setdeleteid(category.id);
                            handleDeleteShow();
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                          >
                            <path
                              fill="currentcolor"
                              d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"
                            />
                          </svg>
                        </div>
                      ) : null}
                    </div>
                  ))
                ) : (
                  <div className="text-center w-100">
                    <div className="loaderWrap">
                      <div className="loader-15"></div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {!pageLoader ? (
              dataSite &&
              dataSite.map((site) => (
                <div className="sitesWrap align-items-center" key={site.id}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center mb-3">
                      <div className="svgWrap siteCateIcon marginright">
                        <img
                          src={`${process.env.REACT_APP_UPLOADS_URL}icons/${site.icon}`}
                          alt="img"
                        />
                        {/* <VideoSvg /> */}
                      </div>
                      <h6 className="mb-0">{site.name}</h6>
                    </div>
                    {permission_module?.create === true ? (
                      <div
                        className="mybtn"
                        onClick={() => {
                          handleShow();
                          setIsupdate(false);
                          setCategoryName("CategorySite");
                          setCategoryId(site.id);
                        }}
                      >
                        Add
                      </div>
                    ) : null}
                  </div>
                  <div className="sites_list">
                    <div className="d-flex flex-wrap">
                      {site.categoryData?.length > 0 ? (
                        site.categoryData.map((category, categoryIndex) => {
                          return (
                            <div
                              className="d-flex sitecategorywrap bg align-items-center"
                              key={category.id}
                            >
                              <div className="siteCateIcon marginright">
                                <img
                                  src={`${process.env.REACT_APP_UPLOADS_URL}icons/${category.icon}`}
                                  alt="siteCat"
                                />
                              </div>
                              <div className="siteCatContent">
                                {category.site_name}
                              </div>
                              {permission_module?.update === true ? (
                                <div
                                  className="iconWrap edit"
                                  onClick={() => {
                                    setId(category.id);
                                    setCategoryName("CategorySite");
                                    setCategoryId(site.id);
                                    handleUpdate(
                                      category.id,
                                      category.site_name
                                    );
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                  >
                                    <path
                                      fill="currentcolor"
                                      d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"
                                    />
                                  </svg>
                                </div>
                              ) : null}
                              {permission_module?.update === true ? (
                                <div
                                  className="iconWrap view"
                                  onClick={() =>
                                    handleStatusChange(
                                      !category.status,
                                      category.id,
                                      category.site_name
                                    )
                                  }
                                >
                                  {category.status === true ? (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 576 512"
                                    >
                                      <path
                                        fill="currentcolor"
                                        d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"
                                      />
                                    </svg>
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 640 512"
                                    >
                                      <path
                                        fill="currentcolor"
                                        d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z"
                                      />
                                    </svg>
                                  )}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 576 512"
                                  >
                                    <path
                                      fill="currentcolor"
                                      d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"
                                    />
                                  </svg>
                                </div>
                              ) : null}
                              {permission_module?.delete === true ? (
                                <div
                                  className="iconWrap"
                                  onClick={() => {
                                    setmodaltxt("Archive");
                                    setdeleteid(category.id);
                                    handleDeleteShow();
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                  >
                                    <path
                                      fill="currentcolor"
                                      d="M32 32H480c17.7 0 32 14.3 32 32V96c0 17.7-14.3 32-32 32H32C14.3 128 0 113.7 0 96V64C0 46.3 14.3 32 32 32zm0 128H480V416c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V160zm128 80c0 8.8 7.2 16 16 16H336c8.8 0 16-7.2 16-16s-7.2-16-16-16H176c-8.8 0-16 7.2-16 16z"
                                    />
                                  </svg>
                                </div>
                              ) : null}
                            </div>
                          );
                        })
                      ) : (
                        <div className="col-md-12">
                          <div className="not-found">
                            <p>No data found</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center w-100">
                <div className="loaderWrap">
                  <div className="loader-15"></div>
                </div>
              </div>
            )}
            {shows && <Popup />}
          </div>
          <Modal show={showDelete} onHide={handleDeleteClose}>
            <Modal.Header closeButton>
              <Modal.Title>{modaltxt}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to {modaltxt} this {categoryname}.
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                className="mybtn black"
                onClick={handleDeleteClose}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                className="mybtn"
                onClick={() => handleDelete(deleteid)}
              >
                {modaltxt}
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </MyContext.Provider>
  );
}
