import { useFormik } from "formik";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { postApi } from "./Helper/Axios";
import MyContext from "./MyContext";

export default function DynamicForm() {
  const [isLoading, setIsLoading] = useState(false);
  const context = useContext(MyContext);
  const [file, setFile] = useState();
  const [selectedFiles, setSelectedFiles] = useState();

  const [file1, setFile1] = useState();
  const [selectedFiles1, setSelectedFiles1] = useState();
  const id = context.data && context.id;

  const successNotify = (message) =>
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const errorNotify = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  let [uploadMultImg, setUploadMultImg] = useState();

  const formik = useFormik({
    initialValues: context.data,
    validationSchema: context.formConfig.validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (context.title === "wall") {
        const formData = new FormData();
        formData.append("colors", values.colors);
        formData.append("category_id", context.categoryId);
        for (let i = 0; i < uploadMultImg.length; i++) {
          formData.append("image", uploadMultImg[i]);
        }
        values = formData;
      }
      if (context.title === "CategorySite") {
        values = {
          ...values,
          category_id: context.categoryId
        }
      }
      let query =
        context.title === "Search Engine"
          ? "searchengine"
          : context.title === "Trending Search"
            ? "trending"
            : context.title === "Top"
              ? "top"
              : context.title === "Game"
                ? "game"
                : context.title === "CategorySite"
                  ? "site"
                  : context.title === "sites"
                    ? "category"
                    : context.title === "wallpapers"
                      ? "category"
                      : "moreapp";
      let uurl = context.isupdate
        ? `${context.updatteurl}?${query}=${id}`
        : context.url;

      setIsLoading(true);
      await postApi(uurl, values, true, context.axiosHeader)
        .then((res) => {
          if (res.status === 200) {
            successNotify(res.message);
            formik.resetForm();
            context.fetchData();
            context.handleClose();
          }
          if (res.status === 422) {
            errorNotify(res.data.errors[0].message);
          }
        })
        .catch((err) => {
          errorNotify(err.data);
        });
    },

  });
  const handleDelete = async (file, status) => {
    let array = selectedFiles && [...selectedFiles];
    if (status === "selectedFiles" && file >= 0 && file < array.length) {
      array && array.splice(file, 1);

      setSelectedFiles(array);
    }
  };
  return (
    <form
      onSubmit={formik.handleSubmit}
      className={
        context.title === "Profile"
          ? "profileform d-flex justify-content-between"
          : ""
      }
    >
      {context.formConfig.fields.map((field) => (
        <div key={field.name} className={field.class}>
          <label className="popup__label">{field.label}</label>
          {/* Render different input types based on field.type */}
          {field.type === "select" ? (
            // Render select dropdown
            <div className="selectWrap mb-3">
              <select
                name={field.name}
                onChange={formik.handleChange}
                className={
                  "my-select " + (formik.errors[field.name] ? "errorinput" : "")
                }
              >
                <option value="">Select {field.label}</option>
                {field.options &&
                  field.options.map((option) => (
                    <option
                      key={option.id}
                      value={option.id}
                      selected={option.id === formik.values[field.name]}
                    >
                      {option.name}
                    </option>
                  ))}
              </select>
            </div>
          ) : field.type === "file" ? (
            <div className="imageclass mb-3 d-flex">
              <div className="imageWrapcustum marginright">
                <input
                  type="file"
                  name={field.name}
                  onChange={(e) => {
                    formik.setFieldValue(field.name, e.target.files[0]);
                    setFile(URL.createObjectURL(e.target.files[0]));
                  }}
                />
              </div>
              {/* context.data */}

              {file ? (
                <div className="imageWrap profile  mt-2 mb-2">
                  <img src={file} alt="image12" />
                </div>
              ) : context.data.icon ? (
                <div className="imageWrap profile mt-2 mb-2">
                  <img
                    className="img-thumbnail"
                    src={`${process.env.REACT_APP_UPLOADS_URL}icons/${formik.values.icon}`}
                    alt={formik.values.icon}
                  />
                </div>
              ) : context.data.logo ? (
                <div className="imageWrap profile mt-2 mb-2">
                  <img
                    className="img-thumbnail"
                    src={`${process.env.REACT_APP_UPLOADS_URL}logos/${formik.values.logo}`}
                    alt={formik.values.logo}
                  />
                </div>
              ) : context.data.image ? (
                <div className="imageWrap profile mt-2 mb-2">
                  <img
                    className="img-thumbnail"
                    src={`${process.env.REACT_APP_UPLOADS_URL}images/${formik.values.image}`}
                    alt={formik.values.flag}
                  />
                </div>
              ) : null}
            </div>
          ) : field.type === "file1" ? (
            <div className="imageclass mb-3 d-flex">
              <div className="imageWrapcustum marginright">
                <input
                  type="file"
                  name={field.name}
                  onChange={(e) => {
                    formik.setFieldValue(field.name, e.target.files[0]);
                    setFile1(URL.createObjectURL(e.target.files[0]));
                  }}
                />
              </div>
              {/* context.data */}

              {file1 ? (
                <div className="imageWrap profile  mt-2 mb-2">
                  <img src={file1} alt="image12" />
                </div>
              ) : context.data.bg_image ? (
                <div className="imageWrap profile mt-2 mb-2">
                  <img
                    className="img-thumbnail"
                    src={`${process.env.REACT_APP_UPLOADS_URL}icons/${formik.values.bg_image}`}
                    alt={formik.values.bg_image}
                  />
                </div>
              ) : null}
            </div>
          ) : field.type === "switch" ? (
            <label className="switch">
              <input
                type="checkbox"
                name={field.name}
                checked={formik.values[field.name] || 0}
                // value={formik.values[field.name]}
                onChange={(e) => {
                  formik.setFieldValue(
                    field.name,
                    formik.values[field.name] === 1 ? 0 : 1
                  );
                }}
              />
              <span className="slider round"></span>
            </label>
          ) : field.type === "textarea" ? (
            <div className="form-input">
              <textarea
                name={field.name}
                value={formik.values[field.name]}
                onChange={formik.handleChange}
                className={
                  "popup__input " +
                  (formik.errors[field.name] ? "errorinput" : "")
                }
              />
            </div>
          ) : field.type === "files" ? (
            <div className="imageclass mb-3 d-flex">
              <div className="imageWrapcustum marginright">
                <input
                  type="file"
                  multiple
                  name={field.name}
                  onChange={(e) => {
                    setUploadMultImg(e.target.files);
                    setSelectedFiles(Array.from(e.target.files));
                  }}
                />
              </div>
              <div className="error">
                {formik.touched[field.name] && formik.errors[field.name] ? (
                  <p> {formik.errors[field.name]}</p>
                ) : null}
              </div>
              <div className="image-preview-container d-flex">
                {selectedFiles &&
                  selectedFiles.map((file, index) => {
                    if (file.type.startsWith("video/")) {
                      return (
                        <div className="btnrmvwrap">
                          <div className="imageWrap profile mt-2 mb-2">
                            <video
                              key={index}
                              src={URL.createObjectURL(file)}
                              controls
                            />
                          </div>
                          <button
                            className="remove-btn"
                            type="button"
                            onClick={() => handleDelete(index, "selectedFiles")}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 384 512"
                            >
                              <path
                                fill="currentcolor"
                                d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                              />
                            </svg>
                          </button>
                        </div>
                      );
                    } else {
                      return (
                        <div className="btnrmvwrap">
                          <div className="imageWrap profile mt-2 mb-2">
                            <img
                              key={index}
                              src={URL.createObjectURL(file)}
                              alt={`Selected file ${index + 1}`}
                              className="image-preview"
                            />
                          </div>
                          <button
                            className="remove-btn"
                            type="button"
                            onClick={() => handleDelete(index, "selectedFiles")}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 384 512"
                            >
                              <path
                                fill="currentcolor"
                                d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                              />
                            </svg>
                          </button>
                        </div>
                      );
                    }
                  })}
              </div>
            </div>
          ) : field.type === "color" ? (
            <div className="form-input">
              <input
                type={field.type}
                placeholder={field.placeholder}
                name={field.name}
                value={formik.values[field.name]}
                onChange={formik.handleChange}
                className={
                  "popup__input color  " +
                  (formik.errors[field.name] ? "errorinput" : "")
                }
              />
              <div className="error">
                {formik.touched[field.name] && formik.errors[field.name] ? (
                  <p> {formik.errors[field.name]}</p>
                ) : null}
              </div>
            </div>
          ) : (
            // Render other input types (e.g., text, number, email)
            <div className="form-input">
              <input
                type={field.type}
                placeholder={field.placeholder}
                name={field.name}
                value={formik.values[field.name]}
                onChange={formik.handleChange}
                className={
                  "popup__input " +
                  (formik.errors[field.name] ? "errorinput" : "")
                }
              />
              <div className="error">
                {formik.touched[field.name] && formik.errors[field.name] ? (
                  <p> {formik.errors[field.name]}</p>
                ) : null}
              </div>
            </div>
          )}
        </div>
      ))}
      <div className="d-flex justify-content-center ">
        <button
          className="mybtn d-flex justify-content-center"
          type="submit"
        // disabled={isLoading}
        >
          <span className={"loader-1 marginright " + (isLoading ? "show" : "")}>
            {" "}
          </span>
          {context.isupdate
            ? `update ${context.title}`
            : isLoading === true
              ? "Loading"
              : `add ${context.title}`}
        </button>
      </div>
    </form>
  );
}

