import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { getApi } from "../Helper/Axios";
import { loginUser } from "../../redux/authSlice";
import { useDispatch, useSelector } from "react-redux";
import logoImage from "../../images/exspeed (1).svg";

import { ReactComponent as DashboardSvg } from "../../images/dashboard.svg";
import { ReactComponent as UserSvg } from "../../images/profile.svg";
import { ReactComponent as SearchEngineSvg } from "../../images/search.svg";
import { ReactComponent as SitesSvg } from "../../images/sites.svg";
import { ReactComponent as WallpapersSvg } from "../../images/wallpapers.svg";
import { ReactComponent as TrendingSearchSvg } from "../../images/trending.svg";
import { ReactComponent as GameSvg } from "../../images/game.svg";
import { ReactComponent as ArchiveSvg } from "../../images/archive.svg";
import { ReactComponent as SettingSvg } from "../../images/settings.svg";
import { ReactComponent as MoreappsSvg } from "../../images/activity.svg";

export default function Sidebar() {
  const user = useSelector((state) => state.auth.user);
  const location = useLocation();
  const path = location.pathname;
  const dispatch = useDispatch();
  useEffect(() => {
    handleGetUser();
  }, []);

  const handleGetUser = async () => {
    await getApi("admin-user", true)
      .then(({ data }) => {
        dispatch(loginUser(data));
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div className="navbarclass">
        <label htmlFor="menu" className="icon">
          <div className="menu d-flex justify-content-between">
            <div className="logo-wrap">
              <img src={logoImage} alt="logo" />
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" strokeWidth="2"  strokeLinecap="round" strokeLinejoin="round" viewBox="0 0 24 24">
              <path d="M22 12H3" stroke="#11142d"></path>
              <g stroke="#808191">
                <path d="M22 4H13"></path>
                <path opacity=".301" d="M22 20H13"></path>
              </g>
              <path d="M7 7l-5 5 5 5" stroke="#11142d"></path>
            </svg>
          </div>
        </label>
        <nav>
          <div className="menu-title">
            <p>Admin tools</p>
          </div>
          <ul className="sidemenu">
            <li className={`${path}` === "/dashboard" ? "active" : ""}>
              <NavLink aria-current="page" to="/dashboard">
                <div className="svgwarp">
                  <DashboardSvg />
                </div>
                Dashboard
              </NavLink>
            </li>
            <li className={`${path}` === "/users" ? "active" : ""}>
              <NavLink aria-current="page" to="/users">
                <div className="svgwarp">
                  <UserSvg />
                </div>
                Users
              </NavLink>
            </li>
            <li className={`${path}` === "/koi-users" ? "active" : ""}>
              <NavLink aria-current="page" to="/koi-users">
                <div className="svgwarp">
                  <UserSvg />
                </div>
                Koi Users
              </NavLink>
            </li>
            <li className={`${path}` === "/searchengines" ? "active" : ""}>
              <NavLink aria-current="page" to="/searchengines">
                <div className="svgwarp">
                  <SearchEngineSvg />
                </div>
                Search Engine
              </NavLink>
            </li>

            <li className={`${path}` === "/sites" ? "active" : ""}>
              <NavLink aria-current="page" to="/sites">
                <div className="svgwarp">
                  <SitesSvg />
                </div>
                Sites Category
              </NavLink>
            </li>

            <li className={`${path}` === "/wallpapers" ? "active" : ""}>
              <NavLink aria-current="page" to="/wallpapers">
                <div className="svgwarp">
                  <WallpapersSvg />
                </div>
                Wallpapers
              </NavLink>
            </li>

            <li className={`${path}` === "/trendingsearches" ? "active" : ""}>
              <NavLink aria-current="page" to="/trendingsearches">
                <div className="svgwarp">
                  <TrendingSearchSvg />
                </div>
                Trending search
              </NavLink>
            </li>
            <li className={`${path}` === "/games" ? "active" : ""}>
              <NavLink aria-current="page" to="/games">
                <div className="svgwarp">
                  <GameSvg />
                </div>
                Game
              </NavLink>
            </li>
            <li className={`${path}` === "/top" ? "active" : ""}>
              <NavLink aria-current="page" to="/top">
                <div className="svgwarp">
                  <GameSvg />
                </div>
                Top Sites
              </NavLink>
            </li>
            <li className={`${path}` === "/moreapps" ? "active" : ""}>
              <NavLink aria-current="page" to="/moreapps">
                <div className="svgwarp">
                  <MoreappsSvg />
                </div>
                More Apps
              </NavLink>
            </li>
            {user && user.is_superadmin === 1 ? (
              <>
                <li className={`${path}` === "/archive" ? "active" : ""}>
                  <NavLink aria-current="page" to="/archive">
                    <div className="svgwarp">
                      <ArchiveSvg />
                    </div>
                    Archive
                  </NavLink>
                </li>
                <li className={`${path}` === "/settings" ? "active" : ""}>
                  <NavLink aria-current="page" to="/settings">
                    <div className="svgwarp">
                      <SettingSvg />
                    </div>
                    Settings
                  </NavLink>
                </li>
              </>
            ) : null}
          </ul>
        </nav>
      </div>
    </>
  );
}
